import { Details, Summary, Heading, Button, Link } from '@troon/ui';
import { createMemo, createSignal, For, mergeProps, Show } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { gql } from '../../graphql';
import type { FacilityByStateFragment } from '../../graphql';

type Props = {
	facilities?: Array<FacilityByStateFragment>;
	columns?: number;
	expandAll?: boolean;
	inverse?: boolean;
	grouping?: 'state' | 'country';
};

export function FacilitiesByGrouping(inputProps: Props) {
	const props = mergeProps({ columns: 3, expandAll: true, grouping: 'state' }, inputProps);
	const [expandAll, setExpandAll] = createSignal(false);

	const data = createMemo(() => {
		const compare = new Intl.Collator('en').compare;

		const byState = Object.entries(
			(props.facilities ?? []).reduce(
				(memo, facility) => {
					const state = facility.metadata?.address?.[props.grouping as 'state' | 'country'];
					if (!state) {
						return memo;
					}
					if (!(state in memo)) {
						memo[state] = [];
					}
					memo[state]!.push(facility);
					return memo;
				},
				{} as Record<string, Array<FacilityByStateFragment>>,
			),
		).sort((a, b) => compare(a[0], b[0]));

		const chunkSize = Math.max(Math.ceil(byState.length / props.columns), 1);
		const byStateChunks = Array.from({ length: Math.min(props.columns, byState.length) }, (_, i) =>
			byState.slice(i * chunkSize, (i + 1) * chunkSize),
		);

		return byStateChunks.filter((chunk) => !!chunk.length);
	});

	return (
		<div role="list" class="relative z-0 grid grid-cols-3 gap-x-8">
			<For each={data()} fallback={<div role="listitem">No Facilities found.</div>}>
				{(column, columnIndex) => (
					<div role="presentation" class="col-span-3 lg:col-span-1">
						<div
							role="presentation"
							class={twJoin(
								'divide-y border',
								props.inverse ? 'divide-brand-600 border-brand-600' : 'divide-neutral border-neutral',
							)}
						>
							<For each={column}>
								{(byState) => (
									<div role="listitem" class="text-nowrap">
										<Details class={props.inverse ? 'bg-brand-700' : 'bg-white'} open={expandAll() ? true : undefined}>
											<Summary class="mb-0 p-4 group-open:mb-0">
												<Heading as="h3" size="h5">
													{byState[0]}
												</Heading>
											</Summary>
											<ul class="flex flex-col gap-6 p-4 pt-0">
												<For each={byState[1]}>
													{(facility) => (
														<li class="relative flex flex-col gap-1">
															<Heading as="h4" size="p">
																<Link
																	href={`/course/${facility.slug}`}
																	class="after:absolute after:inset-0 hover:underline"
																>
																	{facility.name}
																</Link>
															</Heading>
															<p class="text-sm">
																{facility.metadata?.address?.city}, {facility.metadata?.address?.state}
															</p>
														</li>
													)}
												</For>
											</ul>
										</Details>
									</div>
								)}
							</For>
						</div>
						<Show when={data() && columnIndex() === (data().length ?? 0) - 1}>
							<div role="listitem" class="flex justify-center p-4">
								<Button
									type="button"
									size="sm"
									appearance="transparent-current"
									class="normal-case"
									onClick={() => setExpandAll((e) => !e)}
								>
									<Show when={expandAll()} fallback="Expand all">
										Collapse all
									</Show>
								</Button>
							</div>
						</Show>
					</div>
				)}
			</For>
		</div>
	);
}

gql(`fragment FacilityByState on Facility {
  slug
  name
  metadata {
    address {
      city
      state
			country
    }
  }
}`);
